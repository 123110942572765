<template>
    <div class="contact-us">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="closePopup()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="closePopup()"></i>
                    <i class="bi bi-hdd-stack"></i>
                    <div class="title">Would you like to increase your {{ message }} limit?
                        <br>Please contact cs@tlive.hk for assistance.
                    </div>
                    <div class="button-list">
                        <div class="button cancel-button" @click="closePopup()">Not Now</div>
                        <div class="button" @click="closePopup()">Contact</div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'contactUs',
    props: {
        message: { type: String, default: null },
    },
    data() {
        return {
            isShowPopup: true,

            doc_id: 0,
            isUploading: false,
        };
    },
    methods: {

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleContactUsPopup');
        },

    },
}
</script>

<style scoped>
.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    width: 20%;
    height: 20%;
    min-width: 530px;
    min-height: 220px;
    font-size: 14px;
}

.popup-mask .popup-content i {
    font-size: 60px;
    color: #F58A32;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 5px 0;
    font-weight: bold;
}

.popup-mask .popup-content .button {
    border-radius: 20px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 20%;
    margin: 5px 5px;
    line-height: 35px;
}

.popup-mask .popup-content .button:hover {
    background-color: #F05A27;
}

.popup-mask .popup-content .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.popup-mask .popup-content .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.popup-mask .popup-content .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.popup-mask .popup-content .button-list {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
</style>