<template>
    <div class="edit-meta">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="clearFile()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <!-- <div class="title">Manage Document Meta</div> -->
                    <div class="title">管理文件元数据</div>
                    <div class="content">
                        <div class="file-info">
                            <div class="file-detail">
                                <div class="document-summary">
                                    <div class="file-detail-title">
                                        <!-- <h6>Title:</h6> -->
                                        <h6>标题:</h6>
                                        <input type="text" v-model="fileTitle" />
                                    </div>
                                    <div class="file-detail-description">
                                        <!-- <h6>Description:</h6> -->
                                        <h6>描述:</h6>
                                        <textarea class="scrollbar" rows="1" ref="descriptionTextarea"
                                            v-model="fileDescription" @input="adjustHeight"
                                            onpropertychange="this.style.posHeight = this.scrollHeight"></textarea>
                                    </div>
                                </div>
                                <div class="button-list">
                                    <div class="button cancel-button" @click="clearFile()">
                                        <!-- Cancel -->
                                        取消
                                    </div>
                                    <div :class="{ 'button': true, 'disable-button': this.isUploading }"
                                        @click="saveFile(fileData)">
                                        <!-- Save -->
                                        保存
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
    name: 'editMeta',
    props: {
        doc: { type: Object, default: null },
    },
    data() {
        return {
            isShowPopup: true,

            doc_id: 0,
            fileTitle: '',
            fileDescription: '',
            url: '',
            pdfText: '',
            isTitleAiLoading: false,
            isUploading: false,
        };
    },
    methods: {

        clearFile() {
            this.doc_id = 0;
            this.fileTitle = '';
            this.fileDescription = '';
            this.url = '';

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleEditMetaPopup');
        },

        async saveFile() {
            if (!this.fileTitle) {
                alert("File title should not be empty");
                return;
            }
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const data = {
                "doc_id": this.doc_id,
                "fileTitle": this.fileTitle,
                "fileDescription": this.fileDescription,
            };
            const response = await api.postEditTtileDescription(data);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

        async aiGenerateTitleDescription(prompt = '') {
            if (this.isTitleAiLoading == true) {
                return;
            }
            this.isTitleAiLoading = true;
            let response;
            if (prompt === '') {
                response = await api.postAskTitleDescription("help me to generate another title and some realtive description, reply me a json string only");
            } else {
                response = await api.postAskTitleDescription(prompt);
            }
            this.isTitleAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    // result = JSON.parse("["+response.data[0].attributes.extra.ricoh.result.content.replace(/'/g, '"')+"]")[0];
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];
                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                if (result.title) this.fileTitle = result.title;
                if (result.description) {
                    this.fileDescription = result.description;
                    this.$nextTick(() => {
                        this.adjustHeight();
                    });
                }
            } else {
                alert('Failed to generate title and description. Please try again.');
            }
        },

        async downloadAndReadFile() {
            if (this.url != '') {
                const response = await fetch(this.url);
                const arrayBuffer = await response.arrayBuffer();
                const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
                let textContent = '';
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const content = await page.getTextContent();
                    content.items.forEach(item => {
                        textContent += item.str + ' ';
                    });
                }
                this.pdfText = textContent.trim();
                if (this.pdfText) {
                    this.aiGenerateTitleDescription(this.pdfText);
                } else {
                    console.error('PDF text is empty');
                }
            } else {
                console.error('URL is empty');
            }
        },

        adjustHeight() {
            const textarea = this.$refs.descriptionTextarea;
            const lineHeight = parseInt(getComputedStyle(textarea).lineHeight); // ~21 min=31
            const maxHeight = lineHeight * 3 + 10;

            this.$refs.descriptionTextarea.style.height = 'auto';
            if (this.$refs.descriptionTextarea.scrollHeight <= maxHeight) {
                this.$refs.descriptionTextarea.style.height = this.$refs.descriptionTextarea.scrollHeight + 1 + 'px';
            } else {
                this.$refs.descriptionTextarea.style.height = maxHeight + 'px';
            }
        },

    },

    mounted() {

    },

    watch: {
        doc: {
            handler(newVal) {
                this.doc_id = newVal.id;
                this.fileTitle = newVal.doc_title;
                this.fileDescription = newVal.doc_description;
                this.url = newVal.doc_oss_path;
                this.$nextTick(() => {
                    this.adjustHeight();
                });
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 530px;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 20%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-detail .document-summary {
    padding: 15px 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.content .file-info {
    width: 100%;
    position: relative;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .file-detail-title {
    text-align: left;
}

.content .file-detail .file-detail-title input {
    width: 100%;
    border: unset;
    outline: unset;
    border-bottom: 1px solid gray;
    padding: 5px 5px;
    font-size: 14px;
    background-color: transparent;
}

.content .file-detail .file-detail-description {
    margin-top: 20px;
    text-align: left;
}

.content .file-detail .file-detail-description textarea {
    width: 100%;
    border: unset;
    border-bottom: 1px solid gray;
    outline: unset;
    padding: 0 5px;
    font-size: 14px;
    resize: none;
    align-content: end;
    background-color: transparent;
}

.content .file-detail .file-detail-description .file-detail-description-textarea textarea {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 5px 15px;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.content .file-detail .ai-generate {
    display: flex;
    justify-content: flex-end;
}

.content .file-detail .ai-generate .ai-generate-button {
    border-radius: 20px;
    background-color: crimson;
    color: white;
    padding: 0 10px;
    width: 115px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
}

.content .file-detail .ai-generate .ai-generate-button:hover {
    background-color: firebrick;
}

.content .file-detail .ai-generate .ai-generate-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.loading {
    position: relative;
    width: 20px;
    height: 20px;
}
</style>