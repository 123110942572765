<template>
    <div class="delete-confirm">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="clearFile()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <i class="bi bi-exclamation-triangle-fill"></i>
                    <!-- <div class="title">Are you sure to delete?</div> -->
                    <div class="title">確認要刪除嗎？</div>
                    <div class="button-list">
                        <div class="button cancel-button" @click="clearFile()">
                            <!-- Cancel -->
                            取消
                        </div>
                        <div :class="{ 'button': true, 'disable-button': this.isUploading }"
                            @click="submitFile(fileData)">
                            <!-- Yes -->
                            確認
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';
export default {
    name: 'deleteConfirm',
    props: {
        doc: { type: Object, default: null },
    },
    data() {
        return {
            isShowPopup: true,

            doc_id: 0,
            isUploading: false,
        };
    },
    methods: {

        clearFile() {
            this.doc_id = 0;

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleDeleteConfirmPopup');
        },

        async submitFile() {
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const response = await api.getDeleteDocument(this.doc_id);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

    },

    watch: {
        doc: {
            handler(newVal) {
                this.doc_id = newVal.id;
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<style scoped>
.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    width: 20%;
    height: 20%;
    min-width: 530px;
    min-height: 220px;
}

.popup-mask .popup-content i {
    font-size: 60px;
    color: #F58A32;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 5px 0;
    font-weight: bold;
    font-size: 17px;
}

.popup-mask .popup-content .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 15%;
    margin: 5px 5px;
    line-height: 35px;
}

.popup-mask .popup-content .button:hover {
    background-color: #F05A27;
}

.popup-mask .popup-content .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.popup-mask .popup-content .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.popup-mask .popup-content .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.popup-mask .popup-content .button-list {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
</style>