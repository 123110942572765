<template>
  <div class="item-box">
    <div class="row item-row">
      <div class="col-1 privacy-col">
        <!-- <div v-if="privacy == 0" class="privacy-tag">
          Public
        </div>
        <div v-if="privacy == 1" class="privacy-tag private">
          Private
        </div> -->
        <div v-if="privacy == 0" class="privacy-tag">
          公開
        </div>
        <div v-if="privacy == 1" class="privacy-tag private">
          私密
        </div>
      </div>
      <div class="col-8">
        <div class="file-name text-ellipsis">
          {{ fileName }}
          <span v-if="size != 0">({{ formatBytes(size) }})</span>
        </div>
      </div>
      <div class="col-3">
        <!-- <div class="update-time">
          Last update: {{ updateTime.split(' ')[0] }}
        </div> -->
        <div class="update-time">
          最近更新：{{ updateTime.split(' ')[0] }}
        </div>
      </div>
    </div>
    <div class="row item-row">
      <div class="col-8">
        <div class="item-title">
          <span>
            {{ title }}
          </span>
          <p ref="content" class="more">
            {{ content }}
          </p>
          <!-- <a ref="moreLink" href="#" class="more-link" @click="toggleMore" v-if="!isShowMore">more...</a>
          <a ref="moreLink" href="#" class="more-link" @click="toggleMore" v-else>less...</a> -->
          <a ref="moreLink" href="#" class="more-link" @click="toggleMore" v-if="!isShowMore">更多...</a>
          <a ref="moreLink" href="#" class="more-link" @click="toggleMore" v-else>隱藏</a>
        </div>
        <div class="item-groups">
          <ul>
            <li class="item-group" v-for="group in access_group" :key="group.id">{{ group.dept }} Level {{ group.lvl }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-4">
        <div v-if="isOwner == 1" class="operation">
          <span>
            <!-- owner -->
            擁有者
          </span>
          <div class="operation-button-list">
            <button @click="handleDownload"><i class="bi bi-download"></i></button>
            <button @click="deleteConfirm"><i class="bi bi-x-lg"></i></button>
            <button @click="editMeta"><i class="bi bi-pencil-square"></i></button>
            <button @click="editPermission"><i class="bi bi-person-plus"></i></button>
          </div>
        </div>
        <div v-if="isOwner == 0" class="operation">
          <span>
            <!-- share -->
            共享
          </span>
          <div class="operation-button-list">
            <button @click="handleDownload"><i class="bi bi-download"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'documentItem',

  props: {
    doc_id: { type: Number, default: 0 },
    privacy: { type: Number, default: 0 },
    fileName: { type: String, default: "missing file name" },
    updateTime: { type: String, default: "missing update time" },
    title: { type: String, default: "missing title" },
    content: { type: String, default: "missing content" },
    group: { type: String, default: '' },
    url: { type: String, default: '' },
    doc_uid: { type: Number, default: 0 },
    uid: { type: Number, default: 0 },
    size: { type: Number, default: 0 },
  },

  data() {
    return {
      access_group: [],
      isShowMore: false,
      isOwner: 0,
    }
  },

  methods: {
    sendDocId() {
      this.$emit('changeDocId', this.doc_id);
    },

    editMeta() {
      this.sendDocId();
      this.$emit('toggleEditMetaPopup');
    },

    editPermission() {
      this.sendDocId();
      this.$emit('toggleEditPermissionPopup');
    },

    deleteConfirm() {
      this.sendDocId();
      this.$emit('toggleDeleteConfirmPopup');
    },

    handleDownload() {
      if (this.url) {
        const link = document.createElement('a');
        link.href = this.url;
        link.download = this.fileName;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert('empty url');
      }
    },

    toggleMore() {
      this.isShowMore = !this.isShowMore;
      if (this.isShowMore) {
        this.$refs.content.classList.remove('more');
      } else {
        this.$refs.content.classList.add('more');
      }
    },

    updateMoreButton() {
      if (this.$refs.content && this.$refs.moreLink) {
        if (this.$refs.content.scrollHeight > this.$refs.content.clientHeight) {
          this.$refs.moreLink.style.display = 'block';
        } else {
          this.$refs.moreLink.style.display = 'none';
        }
      }
    },

    formatBytes(bytes) {
      if (bytes === 0) return '0 KB';
      const k = 1024;
      const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
  },

  mounted() {
    this.updateMoreButton();
    if (this.doc_uid == this.uid) {
      this.isOwner = 1;
    } else {
      this.isOwner = 0;
    }
  },

  watch: {
    group: {
      handler(newVal) {
        if (newVal) {
          try {
            this.access_group = JSON.parse(newVal);
          } catch {
            this.access_group = [];
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.min.css";

.item-box {
  background-color: #FFFFFF;
  padding-bottom: 5px;
  height: 100%;
  margin: 5px 0px;
  border-bottom: 1px solid #AAACAF;
  color: #575B5E;
}

.item-box .item-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.item-box .item-row .privacy-col {
  text-align: -webkit-center;
  min-width: 90px;
}

.item-box .item-row .privacy-col .privacy-tag {
  background-color: #5492CA;
  border-radius: 10px;
  margin: 10px 0px;
  width: fit-content;
  padding: 0 15px;
  font-size: 12px;
  color: white;
  max-width: -webkit-fill-available;
  overflow: hidden;
}

.item-box .item-row .privacy-col .privacy-tag.private {
  background-color: #F38D48;
}

.item-box .item-row .file-name {
  margin: 10px 0px;
  text-align: left;
  width: inherit;
}

.item-box .item-row .update-time {
  font-size: 12px;
  margin: 10px 0px;
  margin-left: -35px;
  float: right;
  position: relative;
  right: 10px;
  top: -5px;
}

.item-box .item-row .item-title {
  margin: 0px 10px;
  text-align: left;
}

.item-box .item-row .item-title span {
  color: #333334;
  font-weight: bold;
}

.item-box .item-row .item-title p {
  color: #8D8D91;
  margin: 5px 0;
}

.item-box .item-row .item-title p.more {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-box .item-row .item-title a.more-link {
  text-decoration: none;
  color: gray;
}

.item-box .item-row .item-groups ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.item-box .item-row .item-groups ul li {
  list-style-type: none;
  background-color: #F7C5A6;
  margin: 0 5px 8px 5px;
  border-radius: 20px;
  padding: 0 15px;
  color: #E75D38;
  border: 1px solid #E75D38;
  font-size: 12px;
  font-weight: bold;
}

.item-box .item-row .operation {
  margin: 0 10px;
  text-align: -webkit-right;
}

.item-box .item-row .operation .operation-button-list {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 5px;
  width: fit-content;
  border-radius: 30px;
}

.item-box .item-row .operation span {
  color: #333334;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 5px;
}

.item-box .item-row .operation button {
  min-inline-size: fit-content;
  border-radius: 20px;
  background-color: #C3E5F7;
  border: unset;
  color: #5492CA;
  margin: 0 2px;
  font-size: 15px;
  padding: 0 15px;
}

.item-box .item-row .operation button:hover {
  background-color: moccasin;
}

.item-box .item-row .operation button:active {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  transform: scale(0.98);
}
</style>