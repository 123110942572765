<template>
    <div class="user-info-popup" @click="$emit('toggleUserInfoPopup')">
        <transition name="dropdown">
            <div class="popup-content" v-if="isShowPopup">
                <i class="person-icon bi bi-person"></i>
                <div class="user-email">{{ userEmail }}</div>
                <div class="user-permission">
                    <div v-if="userDept" class="user-dept"><i class="bi bi-star"></i> {{ userDept }}</div>
                    <div v-if="userLevel < 4" class="user-level"><i class="bi bi-bar-chart"></i> Level {{ userLevel }}</div>
                    <div v-if="userLevel == 4" class="user-level"><i class="bi bi-bar-chart"></i> C-Level</div>
                </div>
                <div class="user-function">
                    <button class="button" @click="toggleChangePasswordPopup()">
                        <i class="bi bi-unlock"></i>
                        <!-- Change password -->
                        更改密碼
                    </button>
                    <button class="button" @click.stop="logout()">
                        <i class="bi bi-box-arrow-right"></i>
                        <!-- Sign out -->
                        登出
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';

export default {
    name: 'userInfo',

    props: {
        isShowPopup: { type: Boolean, default: false },
        userEmail: { type: String, default: 'missing email' },
        userDept: { type: String, default: 'missing department' },
        userLevel: { type: String, default: 'missing level' }
    },
    data() {
        return {
        };
    },

    methods: {
        async logout() {
            const response = await api.logout();
            if (!response.error) {
                this.$router.push({ path: '/' });
            } else {
                console.error(response);
            }
        },

        toggleChangePasswordPopup() {
            this.$emit('toggleChangePasswordPopup');
        }
    },


}
</script>

<style scoped>
.user-info-popup {
    position: absolute;
    transform: translateY(20px);
}

.popup-content {
    position: absolute;
    width: 350px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    justify-content: center;
    padding: 25px 5px;
    border: 1px solid lightgray;
}

.popup-content .person-icon {
    border-radius: 50px;
    background: linear-gradient(45deg, #29A4A9, #F58A32);
    color: #FFFFFF;
    font-size: 45px;
    width: 70px;
    margin-bottom: 5px;
}

.popup-content .user-img {
    height: 100px;
    border-radius: 100px;
    margin-bottom: 15px;
}

.popup-content .user-email {
    font-size: 14px;
    color: gray;
    margin-bottom: 30px;
}

.popup-content .user-permission {
    width: 90%;
    display: flex;
    justify-content: space-around;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid gray;
}

.popup-content .user-permission .user-dept {
    font-size: 14px;
    color: gray;
    padding: 3px 10px;
    border-radius: 100px;
    width: 40%;
}

.popup-content .user-permission .user-dept i {
    color: #F58A32;
}

.popup-content .user-permission .user-level {
    font-size: 14px;
    color: gray;
    padding: 3px 10px;
    border-radius: 100px;
    width: 40%;
}

.popup-content .user-permission .user-level i {
    color: #F58A32;
}

.popup-content .user-function {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

.popup-content .user-function i {
    color: #F58A32;
    padding: 0 10px;
    font-size: 20px;
}

.popup-content .user-function .button {
    width: 100%;
    height: 35px;
    background-color: transparent;
    color: gray;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: unset;
}

.popup-content .user-function .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 1s;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>